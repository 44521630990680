<template lang="">
    <div class="stepper-container">
        <div class="stepper" v-for="(step, index) in steps" :key="index">
            <div class="step" :class="defineStep(step.stepNumber)">
                <button class="step-btn" @click="btnPasso(step.stepNumber)">
                    <div class="step-num">
                        {{step.stepNumber}}
                    </div>
                </button>
                <div class="step-title">
                    {{step.stepTitle}}
                </div>
            </div>
            <div class="step-line" v-if="index < (steps.length - 1)"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Stepper",
    props: {
        steps: Array,
        currentStep: Number,
        availableStep: Number,
    },

    emits: ["actualStep"],

    methods: {
        defineStep(number){
            if(number === this.currentStep) return "step-green";
            if(number <= this.availableStep) return "step-blue";
            if(number > this.currentStep) return "step-gray";
        },
        btnPasso(number){
            if (number <= this.availableStep) this.$emit("actualStep", number)
        }
    },
}
</script>

<style src="./StepperStyles.scss" lang="scss">